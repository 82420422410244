/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';

const configuracionusuario = localStorage.getItem('configuracionusuario');
const usuariocognito = localStorage.getItem('usuariocognito');
const modulosusuario = localStorage.getItem('modulosusuario');
const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');
const usuario = localStorage.getItem('usuario');
const langkey = localStorage.getItem('langkey');


export const defaultNavigation: any =

    {
        productionGroup: {
            label: 'Reportes',
            icon: 'heroicons_outline:clipboard-list',
            type: 'collapsable',
            role: ['regular','administrador'],
            order: 1,
            children: [
                {
                    id   : 'factory',
                    titleTraduccion: 'Graficos',
                    title: 'Graficos',
                    type : 'basic',
                    link: '/historico/dashboard'
                },
                {
                    id   : 'factory',
                    titleTraduccion: 'Tablero en tiempo real',
                    title: 'Tablero en tiempo real',
                    type : 'basic',
                    link: '/historico/linea-produccion'
                },
                {
                    id   : 'report',
                    titleTraduccion: 'Consulta de reportes',
                    title: 'Consulta de reportes',
                    type : 'collapsable',
                    children: [
                        {
                            id   : 'sinbache',
                            titleTraduccion: 'Sin bache',
                            title: 'Sin bache',
                            type : 'collapsable',
                            children: [
                                {id: 'bitacora', title: 'Bitácora', type: 'basic', link: '/report/bitacora/false'},
                                {id: 'desperdicio', title: 'Desperdicio', type: 'basic', link: '/report/desperdicio/false'},
                                {id: 'envoltura', title: 'Envoltura', type: 'basic', link: '/report/envoltura/false'},
                                {id: 'generales', title: 'Generales', type: 'basic', link: '/report/generales/false'},
                                {id: 'Mensual', title: 'Mensual', type: 'basic', link: '/report/mensual/false'},
                                {id: 'hoja-de-masas', title: 'Producción de masas', type: 'basic', link: '/report/produccion-masas/false'},
                                {id: 'rendimientos', title: 'Rendimientos', type: 'basic', link: '/report/rendimientos/false'},
                                {id: 'reportesarea', title: 'Reportes de área', type: 'basic', link: '/report/reportes-area/false'},
                                {id: 'ritmos', title: 'Ritmos', type: 'basic', link: '/report/ritmos/false'},
                                {id: 'tiempos', title: 'Tiempos', type: 'basic', link: '/report/tiempos/false'},
                            ]
                        },
                        {
                            id   : 'conbache',
                            titleTraduccion: 'Con bache',
                            title: 'Con bache',
                            type : 'collapsable',
                            children: [
                                {id: 'bitacora', title: 'Bitácora', type: 'basic', link: '/report/bitacora/true'},
                                {id: 'de-carga', title: 'Reportes de carga', type: 'basic', link: '/report/de-carga/true'},
                                {id: 'envoltura', title: 'Envoltura', type: 'basic', link: '/report/envoltura/true'},
                                {id: 'hoja-de-masas', title: 'Producción de masas', type: 'basic', link: '/report/produccion-masas/true'},
                                {id: 'rendimientos', title: 'Rendimientos', type: 'basic', link: '/report/rendimientos/true'},
                                {id: 'tiempos', title: 'Tiempos', type: 'basic', link: '/report/tiempos/true'},
                                {id: 'generales', title: 'Generales', type: 'basic', link: '/report/generales/true'}
                            ]
                        }
                    ]
                }
            ]
        },
        calendars: {
            label: 'Calendarios',
            icon: 'heroicons_outline:calendar',
            type: 'collapsable',
            role: ['regular','administrador'],
            order: 4,
            children: [
                {
                    id   : 'generadorDeCalendrios',
                    titleTraduccion: 'Generador de Calendarios',
                    title: 'Generador de Calendarios',
                    type : 'basic',
                    link: '/calendarios/generadorDeCalendrios'
                },
                {
                    id   : 'buscadorDeCalendarios',
                    titleTraduccion: 'Buscardor de Calendarios',
                    title: 'Buscardor de Calendarios',
                    type : 'basic',
                    link: '/calendarios/buscadorDeCalendarios'
                },
                {
                    id   : 'analisiDeQuejas',
                    titleTraduccion: 'Analisis de Quejas',
                    title: 'Analisis de Quejas',
                    type : 'basic',
                    link: '/calendarios/analisiDeQuejas'
                }
            ]
        },
        rpalineas: {
            label: 'Líneas RPA',
            icon: 'heroicons_outline:cube',
            type: 'basic',
            role: ['regular','administrador'],
            link: 'lineas-rpa',
            order: 5,
        },
        centralizadousuarios: {
            label: 'Usuario centralizado',
            icon: 'heroicons_outline:user-group',
            type: 'collapsable',
            role: ['regular','administrador'],
            order: 7,
            children: [
                {
                    id   : 'uscreportes',
                    titleTraduccion: 'Reporte',
                    title: 'Reporte',
                    type : 'basic',
                    link: '/centralizadousuarios/reporte'
                },
                {
                    id   : 'usclogin',
                    titleTraduccion: 'Login',
                    title: 'Login',
                    type : 'basic',
                    link: '/centralizadousuarios/login'
                },
                {
                    id   : 'uscregistro',
                    titleTraduccion: 'Registro de usuarios',
                    title: 'Registro de usuarios',
                    type : 'basic',
                    link: '/centralizadousuarios/registro'
                },
            ]
        },
        oracle : {
            label: 'Oracle Cloud',
            icon: 'heroicons_outline:cloud',
            type: 'basic',
            link: 'oracleCloud',
            role: ['regular','administrador'],
            order: 6,
        },
        sustainabilityGroup : {
            label: 'GBC Sustentabilidad',
            icon: 'heroicons_outline:globe',
            type: 'collapsable',
            role: ['regular','administrador'],
            order: 3,
            children: [
                {
                    id   : 'sustainability',
                    titleTraduccion: 'Tableros',
                    title: 'Tableros',
                    type : 'basic',
                    link: 'sustainability'
                },
                {
                    id   : 'electricity',
                    titleTraduccion: 'Reporte Energia',
                    title: 'Reporte Energia',
                    type : 'basic',
                    link: 'energia-electricidad'
                },
            ]
        },
        monitoring: {
            label: 'Monitoreo de condiciones',
            icon: 'heroicons_outline:desktop-computer',
            type: 'basic',
            role: ['regular','administrador'],
            link: 'monitoring',
            order: 2,
        },
        apikey: {
            label: 'API KEY',
            icon: 'heroicons_outline:key',
            type: 'basic',
            link: 'apikey/historico',
            role: ['administrador'],
            order: 9
        },
        assingnModules: {
            label: 'Asignacion de modulos',
            icon: 'heroicons_outline:cloud',
            type: 'basic',
            role: ['administrador'],
            link: 'asignarModulos',
            order: 8,
        },
    };

/*
    modulos : FuseNavigationItem[] = [
        {
            id: 'report',
            title: 'Reportes',
            icon: 'heroicons_outline:template',
            type: 'collapsable',
            children: [
                {id: 'hoja-de-masas', title: 'Producción de masas', type: 'basic', link: '/report/produccion-masas'},
                {id: 'envoltura', title: 'Envoltura', type: 'basic', link: '/report/envoltura'},
                {id: 'desperdicio', title: 'Desperdicio', type: 'basic', link: '/report/desperdicio'},
                {id: 'tiempos', title: 'Tiempos', type: 'basic', link: '/report/tiempos'},
                {id: 'ritmos', title: 'Ritmos', type: 'basic', link: '/report/ritmos'},
                {id: 'rendimientos', title: 'Rendimientos', type: 'basic', link: '/report/rendimientos'},
                {id: 'reportesarea', title: 'Reportes de área', type: 'basic', link: '/report/reportes-area'},
                {id: 'bitacora', title: 'Bitácora', type: 'basic', link: '/report/bitacora'},
                {id: 'generales', title: 'Generales', type: 'basic', link: '/report/generales'},
                {id: 'Mensual', title: 'Mensual', type: 'basic', link: '/report/mensual'},
                {id: 'de-carga', title: 'Reportes de carga', type: 'basic', link: '/report/de-carga'}
            ]
        },
        {
            id: "centralizadousuarios",
            title: "Usuarios centralizados",
            icon: "heroicons_outline:template",
            type: "collapsable",
            children: [],
        }
    ];
*/
