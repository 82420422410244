import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { UrlService } from '../url/url_service';
import { AuthService } from 'app/core/auth/auth.service';
import { ReportesService } from '../reportes/reportes_service';

@Injectable({
    providedIn: 'root'
})
export class InicioSesionService {

    url_api: String = "";

    reportes = []

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _urlService: UrlService,
        private _reporteService: ReportesService
    ) {

        this.url_api = this._urlService.apiInicioSesion();
    }

    obtenerConfiguracionUsuario(usuario: string): Observable<any> {

        return this._httpClient.get(`${this.url_api}/configuracion/${usuario}/${localStorage.getItem('langkey')}`).pipe(
            switchMap((response: any) => {

                const estado = response.data.length > 0 ? "true" : "false";

                localStorage.setItem("modulosusuario", JSON.stringify(response.data));
                localStorage.setItem("permisosestado", estado);

                return of(response);
            })
        )
    }
    loginSSO(): Observable<any> {
       
        return this._httpClient.get('http://localhost:4200', {
            params: {
                historico: 'historico',
                
            }
        })
            
    }

    obtenerReportes() {
        this._reporteService.obtenerReportes().subscribe({
            next: (response) => {
                let reportes = this.organizarReportes(response.data)
                return of(reportes)
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

    organizarReportes(reportes) {
        let itemsMenu = reportes.map((r, index) => {
            return {
                id: r.endpoint,
                title: r.reporte,
                type: 'basic',
                link: '/report/' + r.url
            }
        })
        return itemsMenu
    }


}

