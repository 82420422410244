import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-seleccion-linea',
  templateUrl: './seleccion-linea.component.html',
  styleUrls: ['./seleccion-linea.component.scss']
})
export class SeleccionLineaComponent implements OnInit {

    @Input() lineas;
    @Input() isMultiple: boolean= false;
    @Input() linea: any;
    @Input() isMostrarTodos: boolean = false;
    @Output() lineaSeleccionada = new EventEmitter<string>();

    label: string= 'Todos';

    constructor(
      private transloco: TranslocoService
    ) {
      this.transloco.selectTranslate('selectores.todos').subscribe((result) => { this.label = result; });
    }

    ngOnInit(): void {}

    seleccionarLinea(): void {
        this.lineaSeleccionada.emit(this.linea);
    }

    ordenarPorCampo(arr, field): void{
      arr.sort(( a, b ) =>{
        if ( a[field].toLowerCase() < b[field].toLowerCase()){
          return -1;
        }
        if ( a[field].toLowerCase() > b[field].toLowerCase()){
          return 1;
        }
        return 0;
      });
    }

}
