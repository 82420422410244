import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { InicioSesionService } from 'app/core/services/login/inicio_de_sesion_service';
import { NgxSpinnerService } from 'ngx-spinner';
import { helpersUsuariosService } from 'app/core/services/helpers/usuarios/helpers_usuarios_service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;

    @Output() onActualizarMenu = new EventEmitter<string>();

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _inicioSesionService : InicioSesionService,
        private spinner: NgxSpinnerService,
        private _helpersUsuarios: helpersUsuariosService,
        private transloco: TranslocoService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/cerrarSesion']);
    }

    /**
     * Refrescar Módulos del usuario
     */
    regrescarModulos () : void {

        // let usuario = this._helpersUsuarios.obtenerFormatoUsuario(localStorage.getItem('usuariocognito'));

        // this.spinner.show();

        // this._inicioSesionService.obtenerConfiguracionUsuario(usuario).subscribe({
        //     next: (position) => {

        //         this.onActualizarMenu.emit("actualizar");

        //         this.spinner.hide();

        //     },
        //     error: (error) => {

        //         console.error("Erro obtener configuración de usuario");
        //         console.error(error);
        //         this.spinner.hide();
        //     }
        // })

    }
}
