import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

interface IBreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() parts;
  @Input() activePage;
  url: [];
  @Input() titulo: string;

  public breadcrumbs: IBreadCrumb[]

  constructor(
    private router: Router,
    private transloco: TranslocoService,
    private activatedRoute: ActivatedRoute
    ) { 
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    }

  ngOnInit(): void {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
    ).subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    })
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[]{
    let label = route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data.breadcrumb
        : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    let lastRoutePart = '';
    if(path.includes(':bache')){
      lastRoutePart = path.split('/:bache').pop();
    }else{
      lastRoutePart = path.split('/').pop();
    }
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl 
    };

    this.titulo = label;

    const newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

}
