import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-seleccion-fuente',
  templateUrl: './seleccion-fuente.component.html',
  styleUrls: ['./seleccion-fuente.component.scss']
})
export class SeleccionFuenteComponent implements OnInit {
  @Input() fuentes;

  @Output() fuenteSeleccionada = new EventEmitter<string>();

  fuente : string = '';

  constructor(
    private transloco: TranslocoService
  ) { }

  ngOnInit(): void {
    
  }

  seleccionarFuente() : void {
      
      this.fuenteSeleccionada.emit(this.fuente);

  }

}
