
<mat-tab-group animationDuration="0ms">
        <mat-tab label="PDF" style="background-color: red;">
            <ngx-extended-pdf-viewer
                [src]="src_"
                [delayFirstView]="1000"
                [useBrowserLocale]="true"
                [showFindButton]="true"
                [showSidebarButton]="true"
                [showPagingButtons]="true"
                [showZoomButtons]="true"
                [showPresentationModeButton]="true"
                [showOpenFileButton]="false"
                [showPrintButton]="true"
                [showRotateButton]="true"
                [showHandToolButton]="false"
                [showScrollingButton]="true"
                [showSpreadButton]="true"
                [showPropertiesButton]="true"
                [textLayer]="true"
                [filenameForDownload]="tituloReporte"
                formTheme="dark"
                height="600px"
                >
            </ngx-extended-pdf-viewer>
            <!-- (click)="obtenerData()"-->
            <!-- <a mat-raised-button color="primary" title="Descargar pdf" (click)="download()" [disabled]="!src">
                <mat-icon [svgIcon]="'heroicons_outline:document-download'"></mat-icon>
            </a>
            
            <pdf-viewer [src]="src"
                        [show-borders]="true"
                        [render-text]="true"
                        [original-size]="false"
                        [show-borders]="true"
                        style="width: 100%; height: 600px;"
            ></pdf-viewer> -->
        
        </mat-tab>

        <!--

            [render-text]="true"
                        [original-size]="false"
                        [show-borders]="true"
                        style="width: 95%; height: 60vh;border: 1px solid #000;"
        -->

        <mat-tab label={{label}}>

            <app-tabla-reporte [datos]="data"></app-tabla-reporte>

        </mat-tab>
        
</mat-tab-group>

