import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { ReportesComponent } from './modules/reportes/reportes.component';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

@Injectable({providedIn: 'root'})
export class hasRoleAdmin implements CanActivate {
    constructor (
    public router: Router) {}

    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let decode;
        let rolUser;
        try{
            if(localStorage.getItem('accessToken')){
                decode = jwt_decode(localStorage.getItem('accessToken'));
                rolUser = decode['cognito:groups'] && decode['cognito:groups'][0] != '' ? decode['cognito:groups'][0] : 'regular';
            }else{
                rolUser = 'regular';
            }
            if(rolUser === 'administrador'){
                return true
            }else{
                this.router.navigate(['/']);
                return false;
            }
        }catch(error){
            console.log(error);
        }
        this.router.navigate(['/']);
        return false;
    }
}
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'iniciarSesion'},

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    //{path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'principal'},

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            //{path: 'principal', loadChildren: () => import('app/modules/principal/principal.module').then(m => m.PrincipalModule)},
            {path: 'inicio', loadChildren: () => import('app/modules/inicio/inicio.module').then(m => m.InicioModule)},
            {path: 'iniciarSesion', loadChildren: () => import('app/modules/login/iniciar_sesion/sign-in.module').then(m => m.AuthSignInModule)},
            //{path: 'registrarUsuario', loadChildren: () => import('app/modules/login/registro/registro.module').then(m => m.RegistroModule)},
            //{path: 'recuperarContrasenia', loadChildren: () => import('app/modules/login/recuperar-contrasenia/recuperar-contrasenia.module').then(m => m.RecupearContraseniaModule)},
            { path: 'visualizacion', loadChildren: () => import('app/modules/apps/gbc_paperless/visualizacion/visualizacion.module').then(m => m.VisualizacionModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'cerrarSesion', loadChildren: () => import('app/modules/login/cerrar_sesion/sign-out.module').then(m => m.AuthSignOutModule)}
        ]
    },

    // Admin routes
    {
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        path       : '',
        component  : LayoutComponent,
        data:{
            layout:'classy'
        },
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            { path: 'principal', loadChildren: () => import('app/modules/principal/principal.module').then(m => m.PrincipalModule)},
            { path: 'report', loadChildren: () => import('app/modules/reportes/reportes.module').then(m => m.ReportesModule), data: {breadcrumb:'Reportes'}},
            //{ path: 'historico', loadChildren: () => import('app/modules/historico/historico.module').then(m => m.HistoricoModule), data: {breadcrumb:'Histórico'}},
            //{ path: 'centralizadousuarios', loadChildren: () => import('app/modules/usuario-centralizado/usuario-centralizado.module').then(m => m.UsuarioCentralizadoModule), data: {breadcrumb:'Usuarios Centralizados'}},
            //{ path: 'energia-electricidad', loadChildren: () => import('app/modules/electricidad/electricidad.module').then(m => m.ElectricidadModule)},
            //{ path: 'produccion-masas', loadChildren: () => import('app/modules/reportes/componentes/reporte-masas/reporte-masas.module').then(m => m.ReporteMasasModule)}
            // { path: 'envoltura', component: ReporteEnvolturaComponent},
            // { path: 'desperdicios', component: ReporteDesperdicioComponent},
            // { path: 'rendimientos', component: ReporteRendimientosComponent},
            // { path: 'ritmos', component: ReporteRitmosComponent}
            //{ path: 'principal', loadChildren: () => import('app/modules/principal/principal.module').then(m => m.InicioModule)},
            //{ path: 'reportes', loadChildren: () => import('app/modules/reportes/reportes.module').then(m => m.ReportesModule)},
            //{ path: 'oracleCloud', loadChildren: () => import('app/modules/oracleCloud/oracleCloud.module').then(m => m.OracleCloudModule)},
            { path: 'reportes-admin', loadChildren: () => import('app/modules/apps/gbc_paperless/reportes/reports.module').then(m => m.ReportsModule)},
            { path: 'consulta-reportes', loadChildren: () => import('app/modules/apps/gbc_paperless/consulta_de_reportes/query-reports.module').then(m => m.QueryReportsModule)},
            { path: 'reportes-asignacion', loadChildren: () => import('app/modules/apps/gbc_paperless/asignacion_de_reportes/users.module').then(m => m.UsersModule)},
            //{ path: 'lineas-rpa', loadChildren: () => import('app/modules/lineas-rpa/lineas-rpa.module').then(m => m.LineasRpaModule)},
            { path: 'user-admin', loadChildren: () => import('app/modules/apps/gbc_paperless/users-admin/components/users-admin.module').then(m => m.UsersAdminModule)},
            { path: 'templates', loadChildren: () => import('app/modules/apps/gbc_paperless/moduloTemplates/templates.module').then(m => m.TemplatesModule)},
            { path: 'graficas', loadChildren: () => import('app/modules/apps/gbc_paperless/graficas/graficas.module').then(m => m.GraficasReportsModule)},
            //{ path: 'calendarios', loadChildren: () => import('app/modules/calendarios/calendarios.module').then(m => m.CalendariosModule), data: {breadcrumb:'Calendarios'}},
            { path: 'apikey', loadChildren: () => import('app/modules/apikey/apikey.module').then(m => m.ApikeyModule)},
            { path: 'asignarModulos', loadChildren: () => import('app/modules/asignacion-modulos/asignacion-modulos.module').then(m => m.AsignacionModulosModule),canActivate:[hasRoleAdmin]},
            { path: 'graficas-slide', loadChildren: () => import('app/modules/apps/gbc_paperless/graficas-slide/graficasSlide.module').then(m => m.GraficasSlideModule)},
            //{ path: 'sustainability', loadChildren: () => import('app/modules/sustentabilidad/sustentabilidad.module').then(m => m.SustentabilidadModule)},
            //{ path: 'monitoring', loadChildren: () => import('app/modules/monitoreo/monitoreo.module').then(m => m.MonitoreoModule)},
            { path: 'alertas', loadChildren: () => import('app/modules/apps/gbc_paperless/alertas/alertas.module').then(m => m.AlertasModule), data: {breadcrumb:'alertas'}},
            { path: 'cat-Administracion-Plantas-Lineas', loadChildren: () => import('app/modules/apps/gbc_paperless/cat-plantas-lineas/cat-plantas-lineas.module').then(m => m.CatPlantasLineasModule)},
            { path: 'cat-Administracion-Usuarios-Roles', loadChildren: () => import('app/modules/apps/gbc_paperless/cat-users-roles/cat-users-roles.module').then(m => m.CatUsersRolesModule)},
            { path: 'catalogos', loadChildren: () => import('app/modules/apps/gbc_paperless/catalogos/catalogos.module').then(m => m.CatalogosModule)},
            { path: 'graficas-layout', loadChildren: () => import('app/modules/apps/gbc_paperless/layout_graficas/graficas-layout.module').then(m => m.GraficasLayoutModule)},
            { path: 'reportes-web', loadChildren: () => import('app/modules/apps/gbc_paperless/reportesWeb/reportes-web.module').then(m => m.ReportesWebModule)},
        ]
    }

];
