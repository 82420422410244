import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, finalize, of } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import Swal from 'sweetalert2';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    traducciones: any;
    showLoader: number = 0;
    constructor(
       private _authService : AuthService,
       private transloco: TranslocoService,
       private spinner: NgxSpinnerService,
    ) {
        this.getTranslation();
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this._authService.accessToken;
       
        if (!token) {
            return next.handle(req);
        }

        if ( this._authService.comprobarExpiracionSesion() ){
            window.location.reload();
        }

        const headers = req.clone({
            'headers': req.headers
                .set('Authorization', token)
                .set('Content-type', 'application/json')
                .set('Access-Control-Allow-Origin', '*')
                .set('accept-language', localStorage.getItem('langkey'))
        });

        if (this.showLoader === 0) {
            this.spinner.show();
        }
        this.showLoader++;
        
        return next.handle(headers).pipe(
            catchError((error) => {
                if (error.status === 409 && error.error.error.includes('Ya existe una fase con codigo')) {
                    Swal.fire({
                        icon: 'error',
                        text: error.error.error,
                        title: 'Oops...',
                    })
                }
                if (error.error.error === 'Reporte ya fue definido como Template') {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', Swal.stopTimer)
                          toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      })

                      Toast.fire({
                        icon: 'info',
                        title: error.error.error
                      })
                }
                if (error.status === 403) {
                    Swal.fire({
                        icon: 'error',
                        text: this.traducciones['userRols.sinPermso'],
                        title: 'Oops...',
                    })
                }
                if (error.status === 502) {
                    Swal.fire({
                        icon: 'error',
                        text: error.message,
                        title: this.traducciones['alertaInterceptor.502'],
                    })
                }
                if (error.status === 500) {
                    Swal.fire({
                        icon: 'error',
                        text: error.message,
                        title: this.traducciones['alertaInterceptor.500'],
                    })
                }
                return of(error);
            })
        ).pipe(finalize(() => {
            this.hiddeLoader(req);
        }))
    }
    private hiddeLoader(req) {
        this.showLoader--;
        if (this.showLoader === 0) {
            if (req.url.includes('condicionOperaciones/templates/insertaTemplate')) {
                Swal.fire({
                    icon: "success",
                    title: 'Catalogo de condiciones de operación guardada',
                });
            }
            if (req.url.includes('productos/templates/insertaTemplate')) {
                Swal.fire({
                    icon: "success",
                    title: 'Templates Productos guardado',
                });
            }
            this.spinner.hide();
        }
    }
    getTranslation(): void{
        this.transloco.selectTranslation().subscribe(result => {
            this.traducciones = result;
        });
    }
}