<div mat-dialog-content>
    <div style="display: flex;flex-direction: column; margin:1rem auto; width: 500px; padding: 1rem;">
        <mat-form-field appearance="fill">
            <mat-label>Seleccionar bache row...</mat-label>
            <mat-select [(ngModel)]="bacheSelected" name="bache">
                <mat-option *ngFor="let bache of data" [value]="bache">
                  {{bache.fechainferior +  ' - ' + bache.fechasuperior}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions class="flex flex-row-reverse">
    <button mat-raised-button color="primary" (click)="seleccionarBache()">{{'selectores.selBache' | transloco}}</button>
</div>