import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../url/url_service';
import { Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class UsuarioCentralizadoService {
    url: String = "";
    urlGbc: String = "";
    
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _urlService : UrlService,
        private _authService : AuthService,
        private spinner: NgxSpinnerService,
    ) {
        this.url = this._urlService.apiUsuarioCentralizado();
        this.urlGbc = this._urlService.apiGBC();
    }

    obtenerUsuarios(): Observable<any>{
        return this._httpClient.get(`${this.url}/obtenerUsuarios`).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    organizaciones(): Observable<any>{
        return this._httpClient.get(`${this.urlGbc}/plantas`).pipe(
            switchMap((response: any) => {
                return of(response.data);
            })
        )
    }

    obtenerDataByUser(user_name): Observable<any>{
        return this._httpClient.get(`${this.url}/getDatabyUser?user_name=${user_name}`).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    obtenerReportesByOrganizacion(body): Observable<any>{
        return this._httpClient.post(`${this.url}/getReportesByOrganization`, body).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    modificarReporte(reporte): Observable<any>{
        return this._httpClient.put(`${this.url}/updateReport`, reporte).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    obtenerModulos(): Observable<any>{
        return this._httpClient.get(`${this.url}/modulos`).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    guardarRole(body): Observable<any>{
        return this._httpClient.post(`${this.url}/updateRoles`, body).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    guardarOrganizacion(body): Observable<any>{
        return this._httpClient.post(`${this.url}/updateOrganizations`, body).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    obtenerRoles():Observable<any>{
        return this._httpClient.get(`${this.url}/getRoles`).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }



    opcionesHTTPJson():object {

        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Access-Control-Allow-Origin': '*',
                'accept-language': 'es',
                'Authorization' : this._authService.accessToken
            })
        }
    }

    crearReporte(reporte): Observable<any>{
        return this._httpClient.post(`${this.url}/createReport`, reporte).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    eliminarReporte(reporte): Observable<any>{
        return this._httpClient.delete(`${this.url}/removeReport`, { params : reporte }).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }
}
