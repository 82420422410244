import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../url/url_service';
import { Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ReportesService{
    url: String = "";
    urlGbc: String = "";

    constructor(
        private _httpClient: HttpClient,
        //private handler : HttpBackend,
        private _urlService : UrlService,
        private _authService : AuthService,
        private spinner: NgxSpinnerService,
    ) {

        //this._httpClient = new HttpClient(handler);
        this.url = this._urlService.apiReportes();
        this.urlGbc = this._urlService.apiGBC();
    } 

    bufferPdf(body:object):Observable<any>{
        return this._httpClient.post(`${this.url}/buffer`, body).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    obtenerReportes(): Observable<any>{
        //obtener aquí el lenguaje para enviarlo por la url
        return this._httpClient.get(`${this.urlGbc}/reportes/es`).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    reporteMasas(body: object): Observable<any>{
        return this._httpClient.post(`${this.url}/hoja-de-masas`, body).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    obtenerDataReporte(body: object, endpoint: string): Observable<any>{
        this.spinner.show();

        return this._httpClient.post(`${this.url}/${endpoint}`, body).pipe(
            switchMap((response: any) => {
                this.spinner.hide();
                return of(response);
            })
        )
    }

    obtenerBaches(body: object): Observable<any>{
        this.spinner.show();

        return this._httpClient.post(`${this.url}/baches`, body).pipe(
            switchMap((response: any) => {
                this.spinner.hide();
                return of(response);
            })
        )
    }

    opcionesHTTPJson():object {

        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Access-Control-Allow-Origin': '*',
                'accept-language': 'es',
                'Authorization' : this._authService.accessToken
            })
        };
    }

    obtenerReporteRitmos(body: object): Observable<any>{
        return this._httpClient.post(`${this.url}/ritmomasas`, body, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    consultarDatosRitmosMasasSerie(json, planta): Observable<any>{
        return this._httpClient.post(`${this.url}/ritmomasasserie`, { json: json, planta: planta }, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }
    
}