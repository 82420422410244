/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { LocalstorageService } from 'app/core/localstorage/localstorage.service';
import { TranslocoService } from '@ngneat/transloco';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import vJson from '../../../../../../package.json';
import { FactoryService } from 'app/core/services/gbc_paperless/reports/factory.service';
@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    factorys: any[];
    factory: any;
    tMsgMenssge: string;
    @Input() titulo: string;
    factorylocalstorrage: any;
    datos: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();


    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private factoryService: FactoryService,
        private localstorageService: LocalstorageService,
        private transloco: TranslocoService,
        public _location: Location,
        private router: Router,
        private http: HttpClient
    )
    {
      this.datos = vJson;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // this.factoryService.getAllFactory().subscribe((resp: any) =>{
        //     resp = resp.filter(factory => factory.lineas.length > 0);
        //     resp.sort((fact1, fact2) => {
        //       if ( fact1.nombreVisual > fact2.nombreVisual){
        //         return 1;
        //       } else {
        //         return -1;
        //       }
        //     });
        //     this.factorys = resp.map((factory) => {
        //       factory.nombreVisual = factory.nombreVisual.toUpperCase();
        //       return factory;
        //     });
        //   });
          this.getTranslation();

        // Subscribe to navigation data
        this.construirMenuUsuario();

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
            this.factorylocalstorrage = this.localstorageService.getFactoryView();
            this.factory = this.factorylocalstorrage;
    }
    onSelection(event: Event): void {
        const lines = this.factory['lineas'].map(line => ({
            'description': line.nombreLinea,
            'name': line.aliasLinea.toUpperCase()
        }));
        lines.sort((lin1, lin2) => {
          if ( lin1.name > lin2.name){
            return 1;
          } else {
            return -1;
          }
        });
        this.localstorageService.setIpAddress(this.factory.direccionIp);
        this.localstorageService.setFactory(this.factory.nombrePlanta, this.factory.nombreVisual);
        this.localstorageService.setProductionLines(lines);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['reportes-admin']);
    }

    getTranslation(): void{
        this.transloco.selectTranslate('SeleccionarPlanta.noEncontrado').subscribe((result) => { this.tMsgMenssge = result; });
    }

    construirMenuUsuario() : void {

        this._navigationService.get();

        this._navigationService.navigation$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

    }
    /**
     *
     * Función para actualizar el menú del usuario cuando se active el evento de actualización
     */
    actualizarMenuUsuario ( data : string ):void {
      setTimeout(()=>{
        this.construirMenuUsuario();
      },1000)


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
