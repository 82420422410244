
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { UrlService } from '../url/url_service';

@Injectable({providedIn: "root"})
export class ApiVersionService{
    urlHistorico: string = '';
    urlPaperless: string = '';

    constructor(
        private _httpClient: HttpClient,
        private _urlService : UrlService
    ) {
        this.urlHistorico = this._urlService.apiVersion();
        this.urlPaperless = this._urlService.apiVersionCalidad();
    }

    getVersion():Observable<any>{
        return this._httpClient.get(`${this.urlHistorico}/version`).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    getVersionCalidad():Observable<any>{
        return this._httpClient.get(`${this.urlPaperless}`).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    
}