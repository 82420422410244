import { LiveAnnouncer } from "@angular/cdk/a11y";
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";


@Component({
  selector: 'app-tabla-reporte',
  templateUrl: './tabla-reporte.component.html',
  styleUrls: ['./tabla-reporte.component.scss']
})

export class TablaReporteComponent implements AfterViewInit, OnChanges {
   
  @Input() datos: any;

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  dataSource;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _liveAnnouncer: LiveAnnouncer) {
     
  }

  ngOnChanges(changes: SimpleChanges): void {
      
      if ( this.evaluarTamanioDatos() ) {
        
        //Debemos asegurarnos que se termino el proceso de crear la tabla para poder aplicar el paginador y ordenamiento
        $.when( 
           this.dataSource = new MatTableDataSource(this.datos.rows)
        ).then( ( )  => {
          this.dataSource.paginator = this.paginator;//Iniciamos el paginador

          this.dataSource.sort = this.sort; // Iniciamos los datos con ordenamiento
        });

      }
  }
  
  //Evaluar si los datos estan preaprados para ser mostrados en la tabla
  evaluarTamanioDatos() : boolean {

     return Object.keys(this.datos).length > 0

  }
  
  //Excluir jsonextra y codigodocumento
  filtrarClaves(): String[] {
     
    let headers = [];

    const { json_keys } = this.datos;

    if( json_keys ){
      for (let  header of json_keys ) {
        headers.push(header.key);
  }
    }
      
    return headers;
  }

  obtenerAlias(header) : string {

    const { json_keys } = this.datos;
     
    header = json_keys.find((headerKey) => {
          return headerKey.key == header;
    })

    return header.label;
  }

  ngAfterViewInit() {

    
  }

  //Funcion para aplicar el ordenamiento de las columnas
  announceSortChange(sortState: Sort) {
    
    sortState.direction ?  this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`) : this._liveAnnouncer.announce('Sorting cleared');
    
  }
  
  //Filtro para realizar búsquedas
  applyFilter(event: Event) {
    
    const filterValue = (event.target as HTMLInputElement).value;
    
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if ( this.dataSource.paginator) {
       
       this.dataSource.paginator.firstPage();
    
    }
  }

  dd(c) {
    console.log("----------------------");
    console.log(c);
    return c;
  }

}
