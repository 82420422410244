import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {

    FORMATO: string = 'YYYY-MM-dd HH:mm:ss';

    FORMATO_SIN_HORA = "YYYY-MM-dd";

    LOCALE: string = 'en-US';


    /**
     * Constructor
     */
    constructor(
        private transloco: TranslocoService,
    ) { }

    mostrarMensaje(mensaje: string, titulo: string, tipo): void {

        let estatusModal = '';
        this.transloco.selectTranslate('estatusModal.' + titulo).subscribe((result) => { estatusModal = result; });
        let mensajeT = '';
        this.transloco.selectTranslate('mensaje.' + mensaje).subscribe((result) => { mensajeT = result; });

        let config = {
            icon: tipo,
            title: estatusModal,
            text: mensajeT,
        }

        if (tipo == 'warning') {
            let timerInterval;
            config['timer'] = 2000
            config['timerProgressBar'] = true
            config['didOpen'] = () => { Swal.showLoading() }
            config['willClose'] = () => { clearInterval(timerInterval) }
        }

        Swal.fire(config);

    }

    //Obtener codigo de error de una excepcion
    obtenerCodigoError(exception): string {

        const { error } = exception;

        const { errorList } = error;
        //Retornamos solo el codigo
        return errorList.message.split(":")[0];

    }

    formateoDeFechas(fecha): string {

        return formatDate(fecha, this.FORMATO, this.LOCALE)

    }

    formateoDeFechasSinHora(fecha): string {

        return formatDate(fecha, this.FORMATO_SIN_HORA, this.LOCALE)

    }

}
