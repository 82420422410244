import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  // translations

  tMsgTitleDialog: string;
  tMsgMessageDialog: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private transloco: TranslocoService,
  ) {  }

  ngOnInit(): void {}

}



export interface DialogData {
  title: string;
  message: string;
}
