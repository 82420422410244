// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
    production: true,
    urlApiSSO:'https://gbconnected-sso.xaldigitalservices.com',
    urlApiHistorico: 'https://gbconnected-apiv2-dev.xaldigitalservices.com', // dns del api del sitio historico v2
    socketApiHistorico: 'https://gbconnected-apiv2-dev.xaldigitalservices.com',
    urlApiPaperless: 'https://verde-valle-esterilizadores-api-dev.xaldigitalservices.com',//'https://gbconnected-paperless-api-dev.xaldigitalservices.com', // dns del api de paperless ambiente de produccion
    urlApiUpdateControl: "https://updatecontrol.xaldigitalservices.com/updatecontrol", // dns del api de la aplicación del Updatecontrol
    urlSitioWebRedireccionHistoricoV1 : "https://gbconnected-website-dev.xaldigitalservices.com", // url del sito web de producción del histórico V1
    urlWebPlantas: "https://gbconnectedapi.xaldigitalservices.com",
    ActiveDirectory : {
        //clientIdXal: '9602d014-fc09-40cd-ab61-e6f74de66d1d', // Application (client) ID from the app registration
        //authorityXal: 'https://login.microsoftonline.com/4ccf9591-062f-4e95-a7bf-93163f10db86', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        clientId: 'ebd1b248-45b0-46f8-b880-497101243fc2',
        authority: 'https://login.microsoftonline.com/973ba820-4a58-4246-84bf-170e50b3152a',
        redirectUri: 'https://gbconnected-websitev2-dev.xaldigitalservices.com',// This is your redirect URI
        redirectUriT: 'http://localhost:4200'// This is your redirect URI
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
