import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-contenedor-reporte',
  templateUrl: './contenedor-reporte.component.html',
  styleUrls: ['./contenedor-reporte.component.scss']
})
export class ContenedorReporteComponent implements OnInit {
  @Input() src: Observable<any>;
  @Input() tituloReporte: string;
  @Input() data: any[];
  linkSource: string;

  title = 'sampleapp';
 
  public page = 1;
 
  public pageLabel!: string;

  src_ = null;
  
  //Convertir array buffer a base 64
  //base64String = btoa(String.fromCharCode(...new Uint8Array(this.src)));
  suscripciones: Subscription[] = [];
  label: string = 'Tabla';
  
  constructor(
    private transloco: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.transloco.selectTranslate('selectores.tabla').subscribe((result) => { this.label = result; });
    this.suscripciones.push(this.src.subscribe(txt => {
      if(txt != null)
        this.src_ = new Blob([new Uint8Array(txt.data)]);
    }))
    
  }

  ngOnDestroy(): void {
    this.suscripciones.forEach(s => s.unsubscribe())
  }
  
  //Pequeño ejemplo de descarga de reporte
  download():void {
    if(this.src){
      const linkSource = 'data:application/pdf;base64,' + this.src;
      const downloadLink = document.createElement("a");
      const fileName = this.tituloReporte+'.pdf';

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } 
  }

  //Función para convertir base64 a toArrayBuffer ( la api mandara el reporte en este formato entonces esta funcion no es necesaria)
  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, '');
    binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

}
