import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-seleccion-turno',
  templateUrl: './seleccion-turno.component.html',
  styleUrls: ['./seleccion-turno.component.scss']
})
export class SeleccionTurnoComponent implements OnInit {
  @Input() turnos;
  @Input() isMultiple: boolean= false;
  @Output() turnoSeleccionado = new EventEmitter<any>();

  turnosForm = new FormControl('');
  label: string= 'Todos';

  constructor(
    private transloco: TranslocoService
  ) { }

  ngOnInit(): void {}

  seleccionarTurno(): void {
     this.turnoSeleccionado.emit(this.turnosForm.value);
  }
}
