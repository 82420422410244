/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Router } from '@angular/router';
import { IInicioDeSesion, IValidarUsuarioCognito } from '../models/usuarios/usuario_models';
import { helpersUsuariosService } from '../services/helpers/usuarios/helpers_usuarios_service';
import { formatDate } from '@angular/common';
import { UrlService } from '../services/url/url_service';
import { CronJob } from 'cron';
import moment from 'moment';
import { UsuarioCentralizadoService } from '../services/usuarioCentralizado/usuario_centralizado_service';

@Injectable()
export class AuthService {

    private _authenticated: boolean = false;
    url_api: String = "";
    url_api2: String = "";
    url_api_cognito:string ="";

    /**
     * Constructor
     */
    constructor(
        private handler : HttpBackend,
        private _httpClient: HttpClient,
        private _helpersUsuarios: helpersUsuariosService,
        private router: Router,
        private _usuarioCentralizadoService: UsuarioCentralizadoService,
        private _urlService : UrlService,
    ) {
        this._httpClient = new HttpClient(handler);
        this.url_api = this._urlService.apiCognito();
        this.url_api2 = this._urlService.apiCognito2();
        this.url_api_cognito = this._urlService.apiInicioSesion();

        this.refrescarTokenIntervalo();

        //this.comprobarExpiracionSesion();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    /**
     * Setter & getter for refresh token
     */

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credenciales: IInicioDeSesion): Observable<any> {

        // Throw error, if the user is already logged in
        if (this._authenticated === true) {
            return throwError(()=> new Error('El usuario ya ha iniciado sesión.'));
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Access-Control-Allow-Origin': '*'
            })
        };

        return this._httpClient.post(`${this.url_api}/login`, credenciales, httpOptions).pipe(
            switchMap((response: any) => {

                this.configurarCredenciales(response.data, credenciales.usuario, true);

                return of(response);
            })
        )

    }

    iniciado(){

        if ( this.accessToken ) {
            this.router.navigate(['/principal']);
        }
        else{
            this.router.navigate(['/iniciarSesion']);
        }
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token

        return of(true);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {


        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('useradmin');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('configuracionusuario');
        localStorage.removeItem('modulosusuario');
        localStorage.removeItem('jwt');
        //localStorage.removeItem('factoryName');
        //localStorage.removeItem('factoryView');
        //localStorage.removeItem('ipFactory');
        //localStorage.removeItem('productionLines');
        //localStorage.removeItem('productionLine');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken || this.accessToken === 'undefined') {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    validarUsuario(credenciales: IValidarUsuarioCognito) : Observable<any> {

        return this._httpClient.post(`${this.url_api}/cognito/validacion-ad`, credenciales, this._helpersUsuarios.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {

                this.configurarCredenciales(response.data, credenciales.usuario.toLowerCase(), true);

                return of(response);
            })
        )
    }


    //Función que nos permite evaluar diferentes tipos de errores al iniciar sesión
    manejoErroresLogin ( error:string ) : string {
        console.log('error --->>>>>   ',error)
        switch ( error ) {
            case 'Error al iniciar sesion':
            case 'NotAuthorizedException'://Cuando la contraseña es incorrecta
            case 'UserNotFoundException'://Cuando el usuario no esta registrado
                return 'validacionUsuario';
            default ://Cuando existe un error de comunicación con la api
                return 'errorServidor';
         }
    }

    configurarCredenciales( configuracion, usuario, bandera ):void {

        const configuracionUsuario = JSON.parse(localStorage.getItem("configuracionusuario"));

        const format = 'YYYY-MM-dd HH:mm:ss';
        const locale = 'en-US';

        this.accessToken = configuracion.idToken.jwtToken;
        this.refreshToken = configuracion.refreshToken.token;
        localStorage.setItem('jwt', "www");
        localStorage.setItem("usuariocognito", usuario);
        localStorage.setItem("usuario", this._helpersUsuarios.obtenerFormatoUsuario(usuario));
        localStorage.setItem("configuracionusuario", JSON.stringify({
            estado: 1,
            fecha_inicio_sesion: (bandera ? formatDate(new Date(), format, locale)  :  configuracionUsuario.fecha_inicio_sesion),
            fecha_ultimo_refresco_token: formatDate(new Date(), format, locale)
        }));
        document.cookie = `token=${configuracion.idToken.jwtToken};${this._helpersUsuarios.generarFechaExpiracion()};path=/`;
        this.obtenerInformacionUsuario(this._helpersUsuarios.obtenerFormatoUsuario(usuario))
    }

    //Función (cron), para refrescar el token cada 30 minutos
    refrescarTokenIntervalo() : void {

        new CronJob('0 */30 * * * *', () => {

           this.refrescarToken().subscribe({
                next: (response) => {

                    this.configurarCredenciales(response.data, localStorage.getItem("usuariocognito"), false);

                },
                error: (exception) => {
                     console.log(exception);
                }
           });


        }, null, true).start();

    }

    refrescarToken() : Observable<any> {

        let json = { usuario: localStorage.getItem("usuariocognito"), token : localStorage.getItem("refreshToken")}

        return this._httpClient.post( `${this.url_api}/refrescar-token`, json ).pipe(
            switchMap((response: any) => {
                return of(response);

            })
        )

    }

    //Función para verificar si ya expiro la sesión
    comprobarExpiracionSesion() :  boolean {

            const configuracion = JSON.parse(localStorage.getItem("configuracionusuario"));


            const fecha_inicio_sesion = configuracion.fecha_inicio_sesion;
            const fecha_ultima_actualizacion_token = configuracion.fecha_ultimo_refresco_token;
            const fecha_actual = moment();

            var diferencia_horas = fecha_actual.diff(fecha_inicio_sesion, 'hours');

            var diferencia_minutos = fecha_actual.diff(fecha_ultima_actualizacion_token, 'minutes');

            //Si ya pasaron 24 horas del primer login, se cierra la sesión
            if (diferencia_horas >= 24) {

                this.signOut();
                //window.location.reload();
                return true;

            } else if (diferencia_minutos >= 30) { // Si ya pasaron 30 minutos del ultimo refresco de token, se actualiza
                this.refrescarToken();
                return false;

            } else {
                return false;
            }

    }

    obtenerInformacionUsuario(usuariologueo){
        // this._usuarioCentralizadoService.obtenerUsuarios().subscribe({
        //     next: (usuarios) => {
        //         try{
        //             let usuario = usuarios.filter(f => usuariologueo == f.user_name);
        //             localStorage.setItem('role', usuario[0].user_roles ? usuario[0].user_roles[0]:'sin rol');
        //         }catch(error){
        //             console.log(error);
        //         }

        //     },
        //     error: (e) => {
        //         console.log(e)
        //     }
        // })
    }
}
