<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pb-0 pl-6">
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <user [showAvatar]="false" (onActualizarMenu)="actualizarMenuUsuario($event)"></user>
            </div>
        </div>
        <!-- Logo -->
        <div class="p-4 pb-0 pl-6 flex items-center justify-center">
            <img class="" src="assets/images/logo/logo_xaldigital_blanco.png">
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full pt-0">
            <div class="flex flex-col items-center justify-center w-full mt-0">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                </div>
                <br>
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">

                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">

                </div>
                <br>
                <!--
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis text-center text-md leading-normal">
                    <label>Seleccionar planta</label>
                    <ng-select mat-raised-button matBadge="{{'Menu.selecciona' | transloco}}" matBadgeSize="large"
                        [matBadgeHidden]="false" matBadgeColor="warn" class="custom" name="line" [(ngModel)]="factory"
                        (change)="onSelection($event)" notFoundText="{{tMsgMenssge}}">
                        <ng-option class="ok" *ngFor="let factory of factorys" [value]="factory">
                            {{factory.nombreVisual}} </ng-option>
                    </ng-select>
                </div>-->
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img class="max-w-36" src="assets/images/logo/grupobimbologo.png">
        </div> -->
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-16 mb-4 text-center">
            <div>
                <!-- <p class = "">&copy; Copyright 2022 Xaldigital - Todos los derechos reservados</p>
                <p class = "">ver. {{datos.version}}</p> -->
                <app-footer></app-footer>
            </div>
        </div>

    </ng-container>

</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 overflow-auto">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <h3>{{titulo}}</h3>

        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages (onActualizarMenu)="actualizarMenuUsuario($event)"></languages>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->


</div>
