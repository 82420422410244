<!--
<mat-form-field style="width: 100%;margin-top: 2.1%;">

    <input matInput [ngxMatDatetimePicker]="picker" placeholder= "{{'selectores.' + label | transloco }}"
    [(ngModel)]="myDatePicker"  (dateChange)="seleccionarFecha()" class="input_height">

    <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>

    <ngx-mat-datetime-picker #picker [hideTime]="hideTime" [touchUi]="true"></ngx-mat-datetime-picker>

</mat-form-field> -->
    <mat-form-field style="width: 100%;margin-top: 2.1%;">
        <mat-label>{{'selectores.' + label | transloco }}</mat-label>
        <input matInput class="input_height h-[3.6vh]" type="text" [(ngModel)]="format2" (change)="seleccionarFecha()" readonly placeholder= "{{'lineaProduccion.' + placeholder | transloco }}">
        <button (click)="btn()">
            <mat-icon svgIcon="edit_calendar"></mat-icon>
        </button>
    </mat-form-field>
