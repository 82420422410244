import { Component,OnInit } from '@angular/core';
import { ApiVersionService } from 'app/core/services/apiVersion/main';
import packageJson from '../../../../../package.json'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

    version: any = packageJson.version
    versionApi:any;
    versionCalidad:any = '1.0';

    constructor(
      private _apiVersionService: ApiVersionService,
    ) {}

    ngOnInit(){
    //   this._apiVersionService.getVersion().subscribe({
    //     next: resp => {
    //       this.versionApi = resp.version;
    //     }
    //   });

      this._apiVersionService.getVersionCalidad().subscribe({
        next: resp => {
          this.versionCalidad = resp.version;
        }
      });
    }

}


