import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-cabezera',
  templateUrl: './cabezera.component.html',
  styleUrls: ['./cabezera.component.scss']
})
export class CabezeraComponent implements OnInit {

    constructor(private transloco: TranslocoService) { }

    ngOnInit(): void {
    }

}
