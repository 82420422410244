import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthInterceptor } from './core/services/interceptors/interceptor_service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReportesService } from './core/services/reportes/reportes_service';
import { UsuarioCentralizadoService } from './core/services/usuarioCentralizado/usuario_centralizado_service';
import { HistoricoService } from './core/services/historico/historico_service';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AlertasComponent } from './modules/apps/gbc_paperless/alertas/components/moduloAlertas/alertas/alertas.component';
import { IonicModule } from '@ionic/angular';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        ToastrModule.forRoot(),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        //FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        ToastrModule.forRoot(),
        HttpClientModule,
        NgSelectModule,
        NgxSpinnerModule,
        GoogleMapsModule,
        NgxMatSelectSearchModule,
        IonicModule,
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide : HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi   : true
        },
        ReportesService,
        HistoricoService,
        UsuarioCentralizadoService
    ]
})
export class AppModule {
}

