import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { firstValueFrom } from 'rxjs';

@NgModule({
    exports  : [
        TranslocoModule
    ],
    providers: [
        {
            // Provide the default Transloco configuration
            provide : TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs      : [
                    {
                        id   : 'en',
                        label: 'English'
                    },
                    {
                        id   : 'es',
                        label: 'Español'
                    },
                    // {
                    //     id   : 'zh',
                    //     label: '中文'
                    // },
                    // {
                    //     id   : 'it',
                    //     label: 'Italiano'
                    // },
                    // {
                    //     id   : 'tr',
                    //     label: 'Türk'
                    // },
                    // {
                    //     id   : 'pt',
                    //     label: 'Português'
                    // },
                    // {
                    //     id   : 'fr',
                    //     label: 'Français'
                    // },
                    // {
                    //     id   : 'uk',
                    //     label: 'Yкраїнський'
                    // },
                    // {
                    //     id   : 'ru',
                    //     label: 'Pусский'
                    // },
                    // {
                    //     id   : 'ko',
                    //     label: '한국어'
                    // }
                ],
                defaultLang         : 'es',
                fallbackLang        : 'es',
                reRenderOnLangChange: true,
                prodMode            : environment.production
            })
        },
        {
            // Provide the default Transloco loader
            provide : TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            deps      : [TranslocoService],
            useFactory: (translocoService: TranslocoService): any => (): Promise<Translation> => {
                let defaultLang = translocoService.getDefaultLang();
                if (localStorage.getItem('langkey')) {
                    defaultLang = localStorage.getItem('langkey')
                }
                localStorage.setItem('langkey',defaultLang);
                translocoService.setActiveLang(defaultLang);
                return firstValueFrom(translocoService.load(defaultLang));
            },
            multi     : true
        }
    ]
})
export class TranslocoCoreModule {}
