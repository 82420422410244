import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
;
@Injectable({
    providedIn: 'root'
})
export class helpersUsuariosService {


    /**
     * Constructor
     */
    constructor() {

    }

    validarContraseniaRegla() : RegExp {
        return /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/;
    }

    validarSoloNumeros() : RegExp {
        return /^[0-9]*$/;
    }

    obtenerDominioBimbo() : string {
        return "grupobimbo.com";
    }

    obtenerDominioSoporte() : string {
        return "gbsupport.net";
    }


    obtenerFormatoUsuario(usuario:string) : string {
        usuario =  usuario.replace("@", "-at-").toLowerCase();

        return usuario.substring(0, usuario.indexOf("-") );
    }

    generarFechaExpiracion(): string {

        const fecha = new Date();

        fecha.setTime( fecha.getTime() + (1*24*60*60*1000) );

        return `expires ${fecha.toUTCString()}`;
    }

    opcionesHTTPJson():object {

        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': localStorage.getItem('accessToken'),
            })
        };
    }

    obtenerPeticionConAutorizacionUC() : object {

        var auth = btoa("marco:metallica");

        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Basic ' + auth,
                'Access-Control-Allow-Origin': '*',
                'accept-language': 'es',
            })
        };

    }


}
