import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { HelpersService } from 'app/core/services/helpers/helpers_service';
import moment from 'moment';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-seleccion-fecha',
    templateUrl: './seleccion-fecha.component.html',
    styleUrls: ['./seleccion-fecha.component.scss']
})
export class SeleccionFechaComponent implements OnInit {

    //Bandera para saber si estamos seleccionando fecha inicial o fecha final true: fecha inicial, false: fecha final
    @Input() banderaFechaInicialFechaFinal: boolean;

    @Input() hideTime: boolean;

    label: string = 'Fecha final';

    fechaFin: string = 'Fecha final';
    fechaInicio: string = 'Fecha inicial';



    //Evento para manejar la fecha inicial
    @Output() fechaInicial = new EventEmitter<string>();

    //Evento para manejar la fecha final
    @Output() fechaFinal = new EventEmitter<string>();

    myDatePicker: any;
    format: any;
    format2: any;
    placeholder: string;
    constructor(
        private transloco: TranslocoService,
        public dialog: MatDialog,
        private _helpersService: HelpersService,
    ) { }

    ngOnInit(): void {
        //this.getTranslation();
        this.label = this.banderaFechaInicialFechaFinal ? this.fechaInicio : this.fechaFin;
        this.placeholder = this.banderaFechaInicialFechaFinal ? 'selFechaInicial': 'selFechaFinal'
    }

    //Funcion que envia evento para la seleccion de fecha
    seleccionarFecha(): void {
        //Dependiendo el valor de la bandera enviamos el tipo de evento
        this.banderaFechaInicialFechaFinal ? this.fechaInicial.emit(this.myDatePicker) : this.fechaFinal.emit(this.myDatePicker);
        //this.toDate(this.myDatePicker);
    }

    to10(n) {
        if (n < 10) return '0'.concat(n);
        return String(n);
    }

    toDate(date) {
        return date.getFullYear() + '-' + this.to10(date.getMonth() + 1) + '-' + this.to10(date.getDate())//  + ' ' + this.to10(date.getHours()) +':' + this.to10(date.getMinutes()) + ':' + this.to10(date.getSeconds());
    }
    btn(): void {
        const dialogRef = this.dialog.open(modalDatePicker);
        dialogRef.afterClosed().subscribe((result) => {
            this.format = result;
            this.format2 = this._helpersService.formateoDeFechas(this.format);
            this.myDatePicker = result;
            this.banderaFechaInicialFechaFinal ? this.fechaInicial.emit(this.myDatePicker) : this.fechaFinal.emit(this.myDatePicker);
        });
    }
}
@Component({
    selector: 'modalDatePicker',
    templateUrl: 'modalDatePicker.html',
})
export class modalDatePicker {
    fecha: any;
    lang: any;
    time: any;

    constructor(public dialogRef: MatDialogRef<modalDatePicker>,) {
        this.lang = localStorage.getItem('langkey');
    }
    aceptar(): void {
        const fechaCompleta = moment(this.fecha).format('YYYY-MM-DD').toString() + ' ' + moment(this.time).format('HH:mm:ss');
        console.log(fechaCompleta);
        this.dialogRef.close(fechaCompleta);
    }
}
