import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from 'app/material/material.module';
import { CabezeraComponent } from './components/cabezera/cabezera.component';
import { RouterModule } from '@angular/router';
import { FuseCardModule } from '@fuse/components/card';
import { SeleccionPlantaComponent } from './components/seleccion-planta/seleccion-planta.component';
import { modalDatePicker, SeleccionFechaComponent } from './components/seleccion-fecha/seleccion-fecha.component';
import { SeleccionLineaComponent } from './components/seleccion-linea/seleccion-linea.component';
import { TablaReporteComponent } from './components/tabla-reporte/tabla-reporte.component';
import { ContenedorReporteComponent } from './components/contenedor-reporte/contenedor-reporte.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ModalBachesComponent } from './components/modal-baches/modal-baches.component';
import { SeleccionFuenteComponent } from './components/seleccion-fuente/seleccion-fuente.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { GoogleMapsModule } from '@angular/google-maps'
import { SeleccionTurnoComponent } from './components/seleccion-turno/seleccion-turno.component';
import { SeleccionAreaComponent } from './components/seleccion-area/seleccion-area.component';
import { SeleccionReporteComponent } from './components/seleccion-reporte/seleccion-reporte.component';
import { SeleccionEstatusComponent } from './components/seleccion-estatus/seleccion-estatus.component';
import { SeleccionOperacionComponent } from './components/seleccion-operacion/seleccion-operacion.component';
import { SeleccionOrganizacionComponent } from './components/seleccion-organizacion/seleccion-organizacion.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterSelectorComponent } from './components/filter-selector/filter-selector.component';
import { FooterComponent } from './components/footer/footer.component';
import { GraficaConsumoComponent } from './components/grafica-consumo/grafica-consumo.component';

import { IonicModule } from '@ionic/angular';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PdfViewerModule,
        NgxExtendedPdfViewerModule,
        TranslocoModule,
        RouterModule,
        FuseCardModule,
        HighchartsChartModule,
        GoogleMapsModule,
        NgxMatSelectSearchModule,
        IonicModule.forRoot({
            mode: 'ios'
          }),

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PdfViewerModule,
        NgxExtendedPdfViewerModule,
        CabezeraComponent,
        SeleccionPlantaComponent,
        SeleccionFechaComponent,
        SeleccionLineaComponent,
        SeleccionFuenteComponent,
        TablaReporteComponent,
        ContenedorReporteComponent,
        FuseCardModule,
        BreadcrumbsComponent,
        ModalBachesComponent,
        HighchartsChartModule,
        SeleccionTurnoComponent,
        SeleccionAreaComponent,
        SeleccionReporteComponent,
        GoogleMapsModule,
        SeleccionEstatusComponent,
        SeleccionOperacionComponent,
        SeleccionOrganizacionComponent,
        FooterComponent,
        GraficaConsumoComponent,
        modalDatePicker
    ],
    declarations: [
        CabezeraComponent,
        SeleccionPlantaComponent,
        SeleccionFechaComponent,
        SeleccionLineaComponent,
        TablaReporteComponent,
        ContenedorReporteComponent,
        BreadcrumbsComponent,
        ModalBachesComponent,
        SeleccionFuenteComponent,
        DialogComponent,
        SeleccionTurnoComponent,
        SeleccionAreaComponent,
        SeleccionReporteComponent,
        SeleccionEstatusComponent,
        SeleccionOperacionComponent,
        SeleccionOrganizacionComponent,
        FilterSelectorComponent,
        FooterComponent,
        GraficaConsumoComponent,
        modalDatePicker
    ]
})
export class SharedModule
{
}
