
<mat-form-field style="width: 100%;margin-top: 2.1%;">
    <mat-label>{{'selectores.area' | transloco}}</mat-label>
    <mat-select [(ngModel)]="area" placeholder="{{'selectores.area' | transloco}}" class="input_height" (selectionChange)="seleccionarArea()"> 
      <mat-option value="" disabled>{{'oracleReintentos.text_seleccion' | transloco}}</mat-option>
      <mat-option *ngFor="let areaL of areas" value="{{ areaL.descripcion }}">{{ areaL.descripcion }}</mat-option>
    </mat-select>
   
</mat-form-field>

