import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FactoryService {

  baseUrl = environment.urlApiPaperless+'/updatecontrolservices/obtenerPlantas';
  //baseUrl = 'http://localhost:3000/updatecontrolservices/obtenerPlantas';
  constructor(private httpClient: HttpClient) { }
  getAllFactory(): Observable<any[]>{
    return this.httpClient.get<any[]>(this.baseUrl);
  }
}
