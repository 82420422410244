import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PlantasService } from 'app/core/services/plantas/plantas_service';
import { TranslocoService } from '@ngneat/transloco';

interface TreeData {
  name: string
  value: string
  children: TreeData[]
}

@Component({
  selector: 'app-seleccion-planta',
  templateUrl: './seleccion-planta.component.html',
  styleUrls: ['./seleccion-planta.component.scss']
})

export class SeleccionPlantaComponent implements OnInit, OnChanges {

    options: TreeData[] = [];

    value:any;

    plantasAPI = [];
    
    @Input() plantaSel:any = '';

    formularioRegistroUsuarios : FormGroup;

    @Output() plantaSeleccionada = new EventEmitter<any>();

    @Input() plantas = [];
  
    constructor(
      private _constructorFormulario: FormBuilder,
      private _plantasService : PlantasService,
      private transloco: TranslocoService
    ) {}

    ngOnChanges(): void {

        if ( this.plantaSel ) {
           this.plantaSeleccionada.emit(this.plantaSel);
        }
    }

    ngOnInit(): void {}
   
   

    eventoPlantaSeleccionada() : void {
        this.plantaSeleccionada.emit(this.plantaSel);
    }

   ordenarPorCampo(arr, field): void{
      function compare_field( a, b ){
        if ( a[field].toLowerCase() < b[field].toLowerCase()){
          return -1;
        }
        if ( a[field].toLowerCase() > b[field].toLowerCase()){
          return 1;
        }
        return 0;
      }
    
      arr.sort(compare_field);
    }

}
