import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-seleccion-reporte',
  templateUrl: './seleccion-reporte.component.html',
  styleUrls: ['./seleccion-reporte.component.scss']
})
export class SeleccionReporteComponent implements OnInit, OnChanges {

  reporte:string = '';

  @Input() reportes;

  @Output()  reporteSeleccionado = new EventEmitter<string>();

  constructor(
    private transloco: TranslocoService
  ) { }

  ngOnChanges(): void {
    
  }

  ngOnInit(): void {

  }

  seleccionarReporte():void {

      this.reporteSeleccionado.emit(this.reporte);

  }

}
