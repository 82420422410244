import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PlantasService } from 'app/core/services/plantas/plantas_service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-seleccion-organizacion',
  templateUrl: './seleccion-organizacion.component.html',
  styleUrls: ['./seleccion-organizacion.component.scss']
})
export class SeleccionOrganizacionComponent implements OnInit, OnChanges {
  value:any;

  options: [] = [];
   
  @Input() organizacion : string = '';
  plantas = [];

  plantasAPI = [];

  @Output() organizacionSeleccionada = new EventEmitter<any>();

  //@Input() organizacionSet : string;
  

  constructor(
    private _plantasService : PlantasService,
    private transloco: TranslocoService
  ) {
    this.obtenerPlantas();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    
      if ( this.organizacion ) {
        this.organizacionSeleccionada.emit(this.organizacion);
      }
  }

  seleccionarOrganizacion() : void {
        
    this.organizacionSeleccionada.emit(this.organizacion);

  }

  obtenerPlantas(){
       
    this._plantasService.obtenerTodasLasPlantasGBC().subscribe({
       next: ( response ) => {
            //Obtenemos todas las plantas
            this.plantas = response.data;
            this.ordenarPorCampo(this.plantas, 'organizacion')
            this.obtenerOrganizaciones(response.data);
       },
       error: (error) => {
            //Ocurrio un error al consultar las plantas
            console.error("Error: Consultar plantas");
            console.error(error);
       }
    })
  }

  ordenarPorCampo(arr, field): void{
    function compare_field( a, b ){
      if ( a[field].toLowerCase() < b[field].toLowerCase()){
        return -1;
      }
      if ( a[field].toLowerCase() > b[field].toLowerCase()){
        return 1;
      }
      return 0;
    }
    
    arr.sort(compare_field);
  }

  obtenerOrganizaciones( plantas ) {
        
    let organizaciones : any = new Set(plantas.map( function(planta){
        return planta.organizacion;
    }));

    organizaciones = Array.from(organizaciones);

    this.options = organizaciones;

  }



}
