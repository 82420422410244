import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalstorageService {

    constructor() {
    }

    checkFactory(): boolean {
      if ( localStorage.getItem('factoryName') && localStorage.getItem('factoryView') ) {
        return true;
      }
    }
    setProductionLine( productionLine: string ): void {
      localStorage.setItem('productionLine', productionLine);
    }
    setProductionLineName( productionLine: string ): void {
        localStorage.setItem('productionLineName', productionLine);
      }
    setFactory(factoryName: string, factoryView: string): void{
      localStorage.setItem('factoryName', factoryName);
      localStorage.setItem('factoryView', factoryView);
    }
    setIpAddress(ipFactory: string): void{
        localStorage.setItem('ipFactory', ipFactory);
    }

    setUserAdmin(user: string): void{
      localStorage.setItem('useradmin', user);
    }
    setUuidReport(uuid): void{
        localStorage.setItem('uuidReport', uuid);
    }

    getUserAdmin(): string {
      return localStorage.getItem('useradmin');
    }

    getUserName(): string {
      return localStorage.getItem('usuario');
    }

    getUserEmail(): string {
      return localStorage.getItem('usuariocognito');
    }

    getAccessToken(): string {
      return localStorage.getItem('accesToken');
    }

    getFactoryName(): string {
      return localStorage.getItem('factoryName');
    }
    getFactoryView(): string {
        return localStorage.getItem('factoryView');
    }

    setProductionLines(productionLines: any[]): void {
      localStorage.setItem('productionLines', JSON.stringify(productionLines));
    }

    getProductionLines(): any[] {
      return JSON.parse(localStorage.getItem('productionLines'));
    }
    getProductionLineName(): string {
        return localStorage.getItem('productionLineName');
      }

    getIpAddress(): string {
      return localStorage.getItem('ipFactory');
    }

    getProductionLine(): string {
      return localStorage.getItem('productionLine');
    }

    setMapaProduccion( configuracion: any ): void {
      localStorage.mapaproduccion = JSON.stringify( configuracion );
    }

    getMapaProduccion(): string {
      if ( localStorage.mapaproduccion !== undefined ) {
          return JSON.parse( localStorage.mapaproduccion );
      } else {
          return null;
      }
    }
    setShow(show): void{
        localStorage.setItem('show', show);
    }
    getShow(): any{
        return localStorage.getItem('show');
    }
    getUuidRepor(): any{
        return localStorage.getItem('uuidReport');
    }
    setFactoryObject(factoryObject): any{
        localStorage.setItem('factoryObject', factoryObject);
    }
    getFactoryObject(): any{
        return localStorage.getItem('factoryObject');
    }
    setOrg(org): any{
        return localStorage.setItem('org', org);
    }
    getOrg(): any{
        return localStorage.getItem('org');
    }
    setFactoryInfo(factoryInfo): any{
        return localStorage.setItem('factoryInfo', JSON.stringify(factoryInfo));
    }
    getFactoryInfo(): any{
        return localStorage.getItem('factoryInfo');
    }
}
