import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UrlService {

    protected URL_API: String = "";
    protected URL_APIPAPERLESS: string = '';

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
    ) {
        this.URL_API = `${environment.urlApiPaperless}`;
        this.URL_APIPAPERLESS = `${environment.urlApiPaperless}`;
    }

    /**
     *
     * **************************** METODOS PARA OBTENER EL PREFIJOS DE LAS APIS ****************************************
     *
     */

    /* ============ API UPDATE CONTROL *************/
    apiUpdatecontrol(): string { return `${environment.urlApiUpdateControl}/restapp` }

    /* ============ API GBC UPDATE CONTROL *************/
    apiGBC(): string { return `${this.URL_API}/--gbc` }

    /* ============ API PLANTAS *************/
    apiPlantasl(): string { return `${environment.urlWebPlantas}` }

    /* ============ API AUTENTICACIÓN **************/
    apiCognito(): string { return `${this.URL_API}/session` }
    apiCognito2(): string { return `${this.URL_API}` }

    /* ============ API USUARIOS **************/
    apiInicioSesion(): string { return `${this.URL_API}/--usuarios` }

    apiReportes(): String { return `${this.URL_API}/--reportes` }

    apiHistorico(): String { return `${this.URL_API}/--historico` }

    apiUsuarioCentralizado(): String { return `${this.URL_API}/--usuarioCentralizado` }
    /* ============ API ORACLE CLOUD **************/
    apiOracleCloud(): String { return `${this.URL_API}/--oracleCloud` }

    /* ============ API ENERGIA **************/
    apiEnergia(): String { return `${this.URL_API}/--energia` }

    apiLineasRpa(): string { return `${this.URL_API}/--lineasRpa` }

    apiLoginSSO(): String { return `${environment.ActiveDirectory.redirectUriT}` }

    apikeys(): string { return `${this.URL_API}/--apiKeys` }

    apiAsignacionModulos(): string { return `${this.URL_API}/--asignacionModulos` }

    apikeysPaperless(): string { return `${this.URL_APIPAPERLESS}/auth-ApiKey` }

    apiSustentabilidad():string{ return `${this.URL_API}/--sustentabilidad` }

    apiPowerBI():string {return `${this.URL_API}/--power_bi`}

    apiMonitoreo():string {return `${this.URL_API}/--monitoreo`}

    apiVersion():string {return `${this.URL_API}/--version`}

    apiCalendarios() : String {return `${this.URL_API}/--calendarios`}

    apiVersionCalidad():string {return `${this.URL_APIPAPERLESS}`}
}



