<nav class="flex items-center justify-between flex-wrap bg-blue-900 p-6">
       
    <div class="flex items-center flex-shrink-0 text-white mr-6">
        <!--Logotipo de empesa-->
        <span class="font-semibold text-xl tracking-tight">GB Conected</span>
    
    </div>
    
    <!-- Items para la navegación-->
    <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
     
        <div class="text-sm lg:flex-grow">
            
            <!-- Principal -->
            <a [routerLink]="['/inicio']" class="text-lg block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                Home
            </a>
            
            <!-- Iniciar sesión-->
            <a [routerLink]="['/iniciarSesion']" class="text-lg block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                {{'selectores.inicioSesion' | transloco}}
            </a>
            
            <!-- Registro -->
            <a [routerLink]="['/registrarUsuario']" class="text-lg block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                {{'selectores.registrarse' | transloco}}
            </a>

            <!-- Recuperar contraseña -->
            <a [routerLink]="['/recuperarContrasenia']" class="text-lg block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                {{'selectores.recuperarContrasena' | transloco}}
            </a>
        </div>

        <div style="color:white">
            
           
        </div>
    </div>

</nav>