import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { TranslocoService } from '@ngneat/transloco';

import { defaultNavigation } from 'app/core/navigation/navigation-data';
import { FuseNavigationItem } from '@fuse/components/navigation';
import jwt_decode from 'jwt-decode';


@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    private readonly modules: any = defaultNavigation;

    accessToken = localStorage.getItem('accessToken');
    useradmin = localStorage.getItem('useradmin');
    refreshToken = localStorage.getItem('refreshToken');
    configuracionusuario = localStorage.getItem('configuracionusuario');
    modulosusuario = localStorage.getItem('modulosusuario');
        onSubject: any;

    constructor(
        private transloco: TranslocoService,
    ) { }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {

        const navigation: Navigation = {
            compact: this.obtenerModulosDelUsuario(),
            default: this.obtenerModulosDelUsuario(),
            futuristic: this.obtenerModulosDelUsuario(),
            horizontal: this.obtenerModulosDelUsuario()
        };

        this._navigation.next(navigation);

        return of(navigation);
    }

    organizarReportes(reportes){
        const itemsMenu = reportes.map((r, index )=> {
            return {
                id   : r.endpoint,
                title: r.reporte,
                type : 'basic',
                link: r.url
            }
        })
        return itemsMenu
    }

    obtenerReportesUsuario (nombreModulo):FuseNavigationItem[] {

        //Obtenemos los modulos y buscamos el módulo de reportes
        const reportes = JSON.parse(localStorage.getItem('modulosusuario')).find((modulo: any) => modulo.name === nombreModulo);

        //Construimos el listado de reportes, retornando un map con los atributos necesarios
        return reportes.children.map( (reporte)=> {
                let traduccion = '';
                this.transloco.selectTranslate(`menu.${reporte.reporte}`).subscribe((result) => { traduccion = result; });
                return {
                    id   : reporte.endpoint,
                    title: reporte.reporte,
                    traduccion: traduccion,
                    type : 'basic',
                    link:  `/report/${reporte.url}`,
                };

        });

    }

    /**
     *
     * Función para contruir el menú de usuario
     */
    obtenerModulosGeneralesUsuario(): any[] {
        let existeProductionGroup = false;
        let existeSustentabilidadGroup = false;

        //Obtenemos los módulos de usuario
        const modulos = JSON.parse(localStorage.getItem('modulosusuario')) || [];

        modulos.forEach( f => {
            if (f.name === 'sustainabilityGroup') {
                existeSustentabilidadGroup = true;
            }
            if (f.name === 'productionGroup') {
                existeProductionGroup = true;
            }
        });

        if(modulos.length > 0){
            modulos.push({name:'assingnModules',children:[],label: 'Assingn_Modules'});
            modulos.push({name:'apikey',children:[],label: 'API KEY'});
        }

        //Retornamos el mapa de menu que se contruye en base a los módulos
        return modulos.map( (modulo) =>{
            if( !existeProductionGroup ){
                existeProductionGroup = true;
                return this.crearObjetoMenu('productionGroup',modulos);
            }

            if( !existeSustentabilidadGroup ){
                existeSustentabilidadGroup = true;
                return this.crearObjetoMenu('sustainabilityGroup',modulos);
            }
            //Declaramos variable de tipo FuseNavigationItem
            const obj: any = {};
            //Hacemos set de las variables generales(siempre requeridas)
            obj.id = modulo.name;
            if(this.modules[modulo.name]){
                obj.icon = this.modules[modulo.name].icon;
                obj.title =this.modules[modulo.name].label;
                obj.role =  this.modules[modulo.name].role;
                obj.order = this.modules[modulo.name].order;
                this.transloco.selectTranslate(`menu.${this.modules[modulo.name].label}`).subscribe((result) => {obj.traduccion = result; });
                obj.type = this.modules[modulo.name].type;

                if(obj.type === 'collapsable'){
                    /*if(modulo.name === 'report'){
                        let children = this.obtenerReportesUsuario(modulo.name);
                        obj.children = children;
                    }
                    else{*/

                        obj.children = this.modules[modulo.name].children.map((item) =>{
                            let traduccion = '';
                            this.transloco.selectTranslate(`menu.${item.titleTraduccion}`).subscribe((result) => { traduccion = result; });
                            item.traduccion = traduccion;
                            return item;
                        });
                    //}

                    obj.children = obj.children.sort( (a,b) => {
                        if ( a.traduccion > b.traduccion ) { return 1; }
                        if ( a.traduccion < b.traduccion ) { return -1;}
                        return 0;
                    });
                }
                else{
                    obj.link = this.modules[modulo.name].link;
                }
                if( this.modules[modulo.name].external ) {
                    obj.externalLink = true;
                    obj.target = '_blank';
                }
            }

            return obj;
        });

    }

    crearObjetoMenu(moduloGroup, modulos): any{
        const obj: any = {};
        obj.id = moduloGroup;
        obj.icon = this.modules[moduloGroup].icon;
        obj.title =this.modules[moduloGroup].label;
        obj.role =  this.modules[moduloGroup].role;
        obj.order = this.modules[moduloGroup].order;
        this.transloco.selectTranslate(`menu.${this.modules[moduloGroup].label}`).subscribe((result) => {obj.traduccion = result; });
        obj.type = this.modules[moduloGroup].type;
        const children = [];
        this.modules[moduloGroup].children.forEach(f => {
            modulos.forEach( e => {
                if (e.name === f.id) {
                    children.push(f);
                    return;
                }
            });
        });
        obj.children = children.map((item) =>{
            let traduccion = '';
            this.transloco.selectTranslate(`menu.${item.titleTraduccion}`).subscribe((result) => { traduccion = result; });
            item.traduccion = traduccion;
            return item;
        });

        obj.children = obj.children.sort( (a,b) => {
            if ( a.traduccion > b.traduccion ) { return 1; }
            if ( a.traduccion < b.traduccion ) { return -1;}
            return 0;
        });

        return obj;
    }

    //Este metodo sirve para obtener los modulos del usuario
    obtenerModulosDelUsuario(): any[] {
        let decode;
        let rolUser;
        try{
            if(localStorage.getItem('accessToken')){
                decode = jwt_decode(localStorage.getItem('accessToken'));
                if (decode['cognito:groups'].includes('soporte')) {
                    rolUser = 'soporte'
                }else{
                    rolUser = 'regular';
                }
                // rolUser = decode['cognito:groups'] && decode['cognito:groups'][0] !== '' ? decode['cognito:groups'][0] : 'regular';
            }else{
                rolUser = 'regular';
            }
        }catch(error){
            console.log(error);
        }

        const modulosGenerales: any[] = [
            {
                id      : '3',
                title   : 'Reportes',
                type    : 'collapsable',
                icon    : 'memory',
                order   : 2,
                children: [
                    {
                        id   : '3-1',
                        title: 'Administracion de reportes',
                        type : 'basic',
                        icon : 'insert_chart',
                        link : '/reportes-admin',
                        role: ['regular','soporte']
                    },
                    {
                        id   : '3-9',
                        title: 'Catálogos',
                        type : 'collapsable',
                        icon : 'menu_book',
                        //link : '/catalogos',
                        role: ['regular','soporte'],
                        children: [
                            {
                                id   : '3-9',
                                title: 'Catálogo de matrices',
                                type : 'basic',
                                icon : 'feather:clipboard',
                                link : '/catalogos/matrices',
                                role: ['regular','soporte'],
                            },
                            {
                                id   : '3-9',
                                title: 'Sensores',
                                type : 'basic',
                                icon : 'mat_solid:sensors',
                                link : '/catalogos/sensores',
                                role: ['regular','soporte'],
                            },
                            {
                                id   : '3-9',
                                title: 'Fases del proceso térmico',
                                type : 'basic',
                                icon : 'mat_solid:thermostat',
                                link : '/catalogos/fases',
                                role: ['regular','soporte'],
                            },
                            {
                                id   : '3-9',
                                title: 'catalogo_materiales',
                                type : 'basic',
                                icon : 'feather:box',
                                link : '/catalogos/productos',
                                role: ['regular','soporte'],
                            }
                            ,
                            {
                                id   : '3-9',
                                title: 'Listas desplegables',
                                type : 'basic',
                                icon : 'format_list_bulleted',
                                link : '/catalogos/listas-desplegables',
                                role: ['regular','soporte'],
                            }
                        ]
                    },
                    {
                        id   : '3-2',
                        title: 'Asignacion de reportes',
                        type : 'basic',
                        icon : 'today',
                        link : '/reportes-asignacion',
                        role: ['regular','soporte']
                    }
                    ,
                    {
                        id   : '3-4',
                        title: 'Asignar plantas a usuario',
                        type : 'basic',
                        icon : 'heroicons_outline:user',
                        link : 'user-admin',
                        role: ['soporte']
                    },
                    {
                        id   : '3-5',
                        title: 'Templates',
                        type : 'basic',
                        icon : 'heroicons_outline:template',
                        link : 'templates',
                        role: ['regular','soporte']
                    },
                    {
                        id   : '3-6',
                        title: 'graficion',
                        type : 'collapsable',
                        icon : 'heroicons_outline:chart-pie',
                        role: ['regular','soporte'],
                        children: [
                            {
                                id   : '3-6',
                                title: 'Graficas',
                                type : 'basic',
                                icon : 'heroicons_outline:chart-bar',
                                link : 'graficas',
                                role: ['regular','soporte']
                            },
                            {
                                id   : '3-7',
                                title: 'Graficas Slide',
                                type : 'basic',
                                icon : 'heroicons_outline:presentation-chart-line',
                                link : 'graficas-slide',
                                role: ['regular','soporte']
                            },
                            {
                                id   : '3-9',
                                title: 'Gráficas Layout',
                                type : 'basic',
                                icon : 'mat_solid:grid_view',
                                link : 'graficas-layout',
                                role: ['regular','soporte']
                            },
                        ]
                    },
                    {
                        id   : '3-7',
                        title: 'API KEY',
                        type : 'basic',
                        icon : 'heroicons_outline:key',
                        link : 'apikey/calidad',
                        role: ['soporte']
                    }
                    ,
                    {
                        id   : '3-8',
                        title: 'Configuración de alertas',
                        type : 'basic',
                        icon : 'mat_outline:add_alert',
                        link : 'alertas/listaAlertas',
                       role: ['regular','soporte']
                    },
                    {
                        id   : '3-9',
                        title: 'Administración de Plantas y Lineas',
                        type : 'collapsable',
                        icon : 'mat_outline:business',
                       role: ['regular','soporte'],
                       children: [
                        {
                            id   : '3-9-1',
                            title: 'Plantas',
                            type : 'basic',
                            //icon : 'heroicons_outline:office-building',
                            link : 'cat-Administracion-Plantas-Lineas/cat-Plantas',
                           role: ['regular','soporte'],
                        },
                        {
                            id   : '3-9-2',
                            title: 'Lineas',
                            type : 'basic',
                            //icon : 'heroicons_outline:office-building',
                            link : 'cat-Administracion-Plantas-Lineas/cat-Lineas',
                           role: ['regular','soporte'],
                        },
                        {
                            id   : '3-9-9-3',
                            title: 'Turnos',
                            type : 'basic',
                            //icon : 'heroicons_outline:office-building',
                            link : 'cat-Administracion-Plantas-Lineas/cat-Turnos',
                           role: ['regular','soporte'],
                        }
                       ]
                    },
                    {
                        id   : '3-9-9',
                        title: 'Administración de Usuarios y Roles',
                        type : 'collapsable',
                        icon : 'heroicons_outline:users',
                       role: ['soporte'],
                       children: [
                        {
                            id   : '3-9-9-1',
                            title: 'Usuarios',
                            type : 'basic',
                            //icon : 'heroicons_outline:office-building',
                            link : 'cat-Administracion-Usuarios-Roles/cat-Users',
                           role: ['soporte'],
                        },
                        {
                            id   : '3-9-9-2',
                            title: 'Roles',
                            type : 'basic',
                            //icon : 'heroicons_outline:office-building',
                            link : 'cat-Administracion-Usuarios-Roles/cat-Roles',
                           role: ['soporte'],
                        }
                       ]
                    },
                    {
                        id: '4-0-0',
                        title: 'Reportes web',
                        type: 'collapsable',
                        icon:'heroicons_outline:chart-bar',
                        role: ['regular','soporte'],
                        children: [
                            {
                                id   : '4-0-1',
                                title: 'Gráficos',
                                type : 'basic',
                                link : 'reportes-web',
                                icon : 'bar_chart',
                                role: ['regular','soporte'],
                            },
                            {
                                id   : '4-0-2',
                                title: 'Consulta de registro de reportes',
                                type : 'basic',
                                icon : 'map',
                                link : 'consulta-reportes',
                                role: ['regular','soporte']
                            }
                        ]
                    }
                ]
            }
        ]

        const mapaModulosUsuario = this.obtenerModulosGeneralesUsuario().filter(f => f.role && f.role.includes(rolUser));
        let modulosGeneralesChildrenFilter = modulosGenerales[0].children.filter(f => f.role.includes(rolUser))
        modulosGenerales[0].children = modulosGeneralesChildrenFilter;

        modulosGenerales[0].children.sort( (a,b) => {
            if ( a.title > b.title ) { return 1; }
            if ( a.title < b.title ) { return -1;}
            return 0;
        });

        mapaModulosUsuario.sort( (a,b) => {
            if ( a.traduccion > b.traduccion ) { return 1; }
            if ( a.traduccion < b.traduccion ) { return -1;}
            return 0;
        });

        return modulosGenerales;

    }

    redireccionHistoricoV1(): void {

        const accessToken = localStorage.removeItem('accessToken');
        const useradmin = localStorage.removeItem('useradmin');
        const refreshToken = localStorage.removeItem('refreshToken');
        const configuracionusuario = localStorage.removeItem('configuracionusuario');
        const modulosusuario = localStorage.removeItem('modulosusuario');

    }

}
