<div *ngIf="evaluarTamanioDatos()" class="tamanioTabla">

  <!-- Campo para realizar busquedas dentro de la tabla -->
  <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
  </mat-form-field>

  <!--Tabla para pintar los datos del reporte -->
  <table *ngIf="datos" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
      class="mat-elevation-z8">
    
    <!-- Iteramos las columnas -->  
    <ng-container  *ngFor="let header of  filtrarClaves()"  matColumnDef="{{ header }}">
        
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
          {{ obtenerAlias(header) }}
        </th>

        <!-- En base a la columna iterada, accedemos a la propiedad del elemento -->
        <td mat-cell *matCellDef="let element"> {{ element[header] }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="filtrarClaves()"></tr>
    <tr mat-row *matRowDef="let row; columns: filtrarClaves();"></tr>

  </table>
  <!-- Componente para aplicar la paginación -->
  <mat-paginator style="width: 100%" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>


</div>

