<ion-datetime display-format="YYYY.MM.DD" presentation="date" showDefaultTimeLabel="true" [(ngModel)]="fecha" [locale]="lang" >

</ion-datetime>
<div style="text-align: center;">
    <span class="text-lg font-medium">{{'Reportes.selechoora' | transloco}}</span>
    <div class="w-1/4 mx-auto">
        <ngx-mat-timepicker [(ngModel)]="time" [showSeconds]="true"></ngx-mat-timepicker>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'Reportes.Dialogo.boton-eliminar-cancelar' | transloco}}</button>
    <button mat-button (click)="aceptar()">{{'Reportes.Dialogo.boton-eliminar-aceptar' | transloco}}</button>
</div>
