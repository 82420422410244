import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-seleccion-estatus',
  templateUrl: './seleccion-estatus.component.html',
  styleUrls: ['./seleccion-estatus.component.scss']
})
export class SeleccionEstatusComponent implements OnInit {
  @Input() Estatus;

  @Output() estatusSeleccionada = new EventEmitter<string>();

  estatus : string='';

  constructor(
    private transloco: TranslocoService
  ) { }

  ngOnInit(): void {
    
  }

  seleccionarEstatus() : void {
      
      this.estatusSeleccionada.emit(this.estatus);

  }

  ordenarPorCampo(arr, field): void{
    function compare_field( a, b ){
      if ( a[field].toLowerCase() < b[field].toLowerCase()){
        return -1;
      }
      if ( a[field].toLowerCase() > b[field].toLowerCase()){
        return 1;
      }
      return 0;
    }
    
    arr.sort(compare_field);
  }

}
