import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-modal-baches',
  templateUrl: './modal-baches.component.html',
  styleUrls: ['./modal-baches.component.scss']
})
export class ModalBachesComponent implements OnInit {

  //@Input() baches = [];
  bacheSelected: {};
  @Output() bacheSelected_ = new EventEmitter<any>();

  constructor(
    private transloco: TranslocoService,
    public dialogRef: MatDialogRef<ModalBachesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: []
  ) { }

  ngOnInit(): void {
  }

  seleccionarBache() {
    this.dialogRef.close(this.bacheSelected);
  }

}
