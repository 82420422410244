import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../url/url_service';
import { Observable, of, switchMap } from 'rxjs';
import { helpersUsuariosService } from '../helpers/usuarios/helpers_usuarios_service';
import { AuthService } from 'app/core/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable({
    providedIn: 'root'
})

export class PlantasService  {

    url_api: String = "";
    urlGbc: String = "";

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private handler : HttpBackend,
        private _urlService : UrlService,
        private _helpersUsuarios : helpersUsuariosService,
        private _authService : AuthService,
        private spinner: NgxSpinnerService,
    ) {

        this._httpClient = new HttpClient(handler);
        this.url_api = this._urlService.apiUpdatecontrol();
        this.urlGbc = this._urlService.apiGBC();
    }     
    
    obtenerTodasLasPlantas(): Observable<any> {

        return this._httpClient.get(`${this.url_api}/planta/getbytipoproyecto/GBC`, this._helpersUsuarios.obtenerPeticionConAutorizacionUC()).pipe(
            
            switchMap((response: any) => {

                return of(response);
                
            })
        )
    }

    obtenerTodasLasPlantasGBC(): Observable<any> {
        this.spinner.show();
        return this._httpClient.get(`${this.urlGbc}/plantas`, this.opcionesHTTPJson()).pipe(
            
            switchMap((response: any) => {
                this.spinner.hide();
                return of(response);
                
            })
        )
    }

    obtenerPlantasUpdateControl(): Observable<any>{
        return this._httpClient.get(`${this.urlGbc}/planta/getall/true`,this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    opcionesHTTPJson():object {

        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Access-Control-Allow-Origin': '*',
                'accept-language': 'es',
                'Authorization' : this._authService.accessToken
            })
        };
    }
}
