import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../url/url_service';
import { Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class HistoricoService {
    url: String = "";
    urlGbc: String = "";
    
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _urlService : UrlService,
        private _authService : AuthService,
        private spinner: NgxSpinnerService,
    ) {
        this.url = this._urlService.apiHistorico();
        this.urlGbc = this._urlService.apiGBC();
    }

    oee(body:object): Observable<any>{
        return this._httpClient.post(`${this.url}/oee`, body, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    efectividad(body:object): Observable<any>{
        return this._httpClient.post(`${this.url}/efectividad`, body, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    mejoracontinua(body:object): Observable<any>{
        return this._httpClient.post(`${this.url}/mejoracontinua`, body, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    parosmicroparos(body:object): Observable<any>{
        return this._httpClient.post(`${this.url}/parosmicroparos`, body, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    historial(body:object): Observable<any>{
        return this._httpClient.post(`${this.url}/historial`, body, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    plantasPorProyecto(proyecto:string): Observable<any>{
        return this._httpClient.get(`${this.urlGbc}/plantas-proyecto/${proyecto}`, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    plantasFavoritas(usuario:string): Observable<any>{
        return this._httpClient.get(`${this.url}/plantas-favoritas/${usuario}`, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    modificarEliminarFavoritos(configuracion:any): Observable<any>{
        return this._httpClient.post(`${this.url}/plantas-favoritas-modificar-eliminar`,configuracion, this.opcionesHTTPJson()).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        )
    }

    opcionesHTTPJson():object {

        return {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Access-Control-Allow-Origin': '*',
                'accept-language': 'es',
                'Authorization' : this._authService.accessToken
            })
        };
    }
}
