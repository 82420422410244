import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-seleccion-area',
  templateUrl: './seleccion-area.component.html',
  styleUrls: ['./seleccion-area.component.scss']
})
export class SeleccionAreaComponent implements OnInit,OnChanges {

  area:string = '';

  @Output() areaSeleccionada = new EventEmitter<string>();

  @Input() areas;

  constructor( private transloco: TranslocoService) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    
  }

  seleccionarArea(): void {

      this.areaSeleccionada.emit(this.area);
      
  }

}
